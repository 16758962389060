import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosSecondInstance } from '../../../../../axios/axios';
const user = JSON.parse(localStorage.getItem('userData'));
const token = user?.accessToken;


// Async action to fetch paginated data
export const fetchListData = createAsyncThunk('payment/fetchData', async ({ page, limit, filters }, { rejectWithValue }) => {
    try {
        const response = await axiosSecondInstance.post(`/payment/lists`, {
            page,
            limit,
            ...filters,
        });
        return response.data;
    } catch (error) {
        console.log('error payment:>> ', error);
        return rejectWithValue(error.response.data);
    }
}
);

const listSlice = createSlice({
    name: 'payment',
    initialState: {
        data: [],
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
        loading: false,
        error: null,
    },
    reducers: {
        resetList: (state) => {
            state.data = [];
            state.currentPage = 1;
            state.totalPages = 1;
            state.totalRecords = 0;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchListData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data.data;
                state.currentPage = action.payload.data.currentPage;
                state.totalPages = action.payload.data.totalPages;
                state.totalRecords = action.payload.data.totalItems;
            })
            .addCase(fetchListData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { resetList } = listSlice.actions;

export default listSlice.reducer;
